<template>
  <div class="gallery-container">
    <div
      v-if="previewImg"
      class="gallery-container__preview"
    >
      <div class="gallery-container__preview--content">
        <!--<img-->
        <!--  :src="previewImg.url"-->
        <!--  :alt="previewImg.alt"-->
        <!--/>-->

        <InnerImageZoom
          :src="previewImg.url"
          :alt="previewImg.name"
          :zoom-scale="1.5"
          zoom-type="hover"
          :fullscreen-on-mobile="false"
          :hide-close-button="true"
        />
      </div>
    </div>
    <div
      v-hscroll
      class="gallery-container__thumb overflow-auto"
    >
      <div class="gallery-container__thumb--content cursor-pointer">
        <img
          v-for="(img) in images"
          :key="img.id"
          :alt="img.name"
          :src="img.url"
          @mouseover="updateImage(img)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '@/utils/helper';

export default {
  name: 'ProductGallery',
  components: { InnerImageZoom },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    previewImg: null,
    defaultImage: {
      url: `${API_BASE_URL}/storage/no-images.jpg`
    }
  }),

  computed: {
    productImages(){
      if(!this.images?.length) return [this.defaultImage];

      return this.images;
    }
  },

  watch: {
    images: {

      immediate: true,
      deep: true,

      handler(nv){
        if(isEmpty(nv)) {
          this.updateImage(this.productImages[0]);
        }

        this.updateImage(this.productImages[0]);
      }
    }
  },

  mounted() {
    this.updateImage(this.productImages[0]);
  },

  methods: {
    updateImage(img) {
      this.previewImg = img;
    }
  },
};
</script>

<style lang="scss">

.gallery-container {

  &__preview {
    &--content {
      margin-bottom: 10px;

      figure {
        border: 1px solid rgba(128, 128, 128, 0.35);
        border-radius: 3px;
      }
    }
  }

  &__thumb {
    width: 100%;

    &--content {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
      height: 80px;
      gap: 1rem;

      img {
        border: 1px solid rgba(128, 128, 128, 0.35);
        border-radius: 3px;
        padding: 5px;
        height: 80px;
        width: 80px;
      }
    }
  }
}
.img-fluid {
  width:100% !important;
  max-width: 100%;
  height:auto !important;
  max-height: 400px;
}
</style>
