<template>
  <section class="specifications-wrapper">
    <h3 class="text-h6 title">
      Specifications
    </h3>
    <ul class="specifications-container">
      <li
        v-for="specification in specifications"
        :key="specification.id"
        class="specification-item"
      >
        <p class="specification-item--key">
          {{ specification.key }}
        </p>
        <p>
          {{ specification.value }}
        </p>
      </li>
    </ul>
  </section>
</template>

<script>
  export default {
    name: 'ProductSpecifications',
    props: {
      specifications: {
        required: true,
        type: Array,
      }
    },
  }
</script>

<style>
  .specifications-wrapper {
    margin: 2rem 0;
  }

  .title {
    margin-bottom: 0.5rem;
  }

  .specifications-container {
    width: 100%;
    border-radius: 2px;
    outline: 1px solid #eee;
    padding-inline: 0 !important;

  }

  .specification-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 20px;
    padding: 10px 20px;

    text-transform: uppercase;

    &:not(:last-of-type) {
      border-bottom: 1px solid #eee;
    }
  }

  .specification-item--key {
    font-weight: bold;
  }
</style>
