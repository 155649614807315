<template>
  <div>
    <div
      v-for="(attributes, attributeName) in attributesList"
      :key="`${selectedVariant.id}-${attributeName}`"
    >
      <div class="header text-capitalize">
        <b>{{ attributeName }}: </b>
      </div>
      <div class="variations">
        <label
          v-for="attribute in attributes"
          :key="attribute.id"
          :class="['radio variation', { 'unavailable': !attribute.available }]"
          @change="handleChange(attribute)"
        >
          <input
            type="radio"
            :name="attribute.name"
            :checked="attribute.selected"
            :value="attribute.value"
          >
          <span>{{ attribute.value }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, reactive, watch } from 'vue';

export default {
  name: 'Attributes',
  props: {
    product: {
      required: true,
      type: Object,
    },
    selectedVariant: {
      required: true,
      type: Object,
    },
    allVariants: {
      required: true,
      type: Array,
    },
  },
  emits: ['change'],

  setup(props, ctx) {

    const state = reactive({
      lastSelectedAttribute: {
        name: null,
        value: null,
      },
      selectedAttributes: {},
    });

    watch(() => props.selectedVariant, (nv) => {

      // set selected variation's attributes as selected
      state.selectedAttributes = {};

      nv.attributes.forEach((attr) => {
        state.selectedAttributes[attr.name] = attr.value;
      });

      // set last selected attribute when component loads
      // so unavailable attributes can be marked as unavailable
      if (!state.lastSelectedAttribute.name) {
        // take 1st attr
        const firstAttrName = Object.keys(state.selectedAttributes)[0];

        // & set that
        state.lastSelectedAttribute.name = firstAttrName;
        state.lastSelectedAttribute.value = state.selectedAttributes[firstAttrName];
      }

    }, {
      immediate: true
    });

    const selectedVariantAttributes = computed(() => {
      return props.selectedVariant.attributes.reduce((attributes, attribute) => {
        attributes[attribute.name] = attribute.value;
        return attributes;
      }, {});
    });

    const attributesList = computed(() => {
      const attributes = {
        // structure is like following
        // 'Color': {
        //   'Gold': {
        //       available: true
        //       id: 207
        //       name: 'Color'
        //       selected: false
        //       value: 'Gold'
        //       variations: [101, 103]
        //   }
        // }
      };

      props.allVariants.forEach((variant) => {
        if (!variant.attributes) return;
        const isSelectedVariant = (variant.id === props.selectedVariant.id);

        variant.attributes.forEach((attribute) => {

          // find if this attribute is selected
          let selected = false;

          // if attribute is in selected list
          if (attribute.name in state.selectedAttributes) {

            // and the value is same
            // or the attribute is selected variants
            selected = (
              (attribute.value === state.selectedAttributes[attribute.name])
              || isSelectedVariant
            );
          }

          // formatted attributes
          const fAttrs = {
            id: attribute.id,
            name: attribute.name,
            variations: [variant.id],
            value: attribute.value,
            selected,

            // if current variation has this attribute
            available: attribute.name in selectedVariantAttributes.value,
          };

          // if this attribute is not present then add this
          if (!attributes[fAttrs.name]) attributes[fAttrs.name] = {};

          if (attributes[fAttrs.name][fAttrs.value]) {

            // just store new variant id if it's already stored
            attributes[fAttrs.name][fAttrs.value].variations.push(variant.id);
          } else {

            // or store new one
            attributes[fAttrs.name][fAttrs.value] = (fAttrs);
          }
        });
      });

      // set unavailable if not available
      checkAvailability(attributes);

      return attributes;
    });

    function checkAvailability(computedAttrs) {

      for (const cAttrName in computedAttrs) {

        // skip if last selected one
        if (cAttrName === state.lastSelectedAttribute.name) continue;

        const currentAttrTypeValues = computedAttrs[cAttrName];
        const currentlySelectedValue = state.selectedAttributes[cAttrName];

        // check values (except currently selected) for availability
        for (const typeValueName in currentAttrTypeValues) {

          // skip if currently selected one
          if (typeValueName === currentlySelectedValue) continue;

          const checkTypeValue = currentAttrTypeValues[typeValueName];

          const isVariationFound = checkVariationsByLastSelectedAttribute(checkTypeValue.variations);

          checkTypeValue.available = isVariationFound;

        }

      }

    }

    function checkVariationsByLastSelectedAttribute(variationIds) {

      const variations = props.allVariants.filter((variation) => variationIds.includes(variation.id));

      const matchedVariations = variations.filter((variation) => {

        let matched = false;

        variation.attributes.forEach((attr) => {

          // not interested in other attrs that we aren't checking
          if (attr.name !== state.lastSelectedAttribute.name) return;

          const valueMatched = attr.value === state.lastSelectedAttribute.value;

          if (valueMatched) matched = true;

        });

        return matched;

      });

      return matchedVariations.length > 0;
    }

    const handleChange = (attribute) => {

      // set newly selected attribute to selected attributes list
      state.selectedAttributes[attribute.name] = attribute.value;

      state.lastSelectedAttribute.name = attribute.name;
      state.lastSelectedAttribute.value = attribute.value;

      nextTick(() => {
        ctx.emit('change', {
          lastSelectedAttribute: state.lastSelectedAttribute,
          selectedAttributes: state.selectedAttributes
        });
      });
    };

    return {
      attributesList,
      state,
      handleChange,
    };
  }
};
</script>

<style lang="scss" scoped>
.unavailable {
  color: #a5a5a5;
  span {
    border: 1px dashed;
    color: #a5a5a5;
  }
}
</style>
