<template>
  <div>
    <LoadingSpinner v-model="loading" />

    <v-container v-if="variant && product">
      <v-sheet class="my-3">
        <v-row>
          <v-col
            v-if="images.length"
            cols="12"
            lg="5"
          >
            <ProductGallery
              :images="images"
            />
          </v-col>

          <v-col
            cols="12"
            lg="7"
          >
            <v-card-text>
              <v-card-text class="px-0 pt-0">
                <v-btn
                  class="ht-button"
                  color="secondary"
                  outlined
                  @click="backToPreviousPage"
                >
                  <v-icon class="mr-3">
                    mdi-arrow-left-circle
                  </v-icon>
                  Back
                </v-btn>
                <v-btn
                  v-if="availableStock"
                  class="float-end ht-button"
                  color="info"
                  @click.prevent="handleBuyNow"
                >
                  <v-icon class="mr-1">
                    mdi-cart-arrow-right
                  </v-icon>
                  Buy Now
                </v-btn>
              </v-card-text>

              <div class="product-details-page">
                <div class="product-details-page__content">
                  <h2 class="product-details-page__content--title">
                    {{ variant.full_name }}
                  </h2>
                  <h5 class="info--text">
                    Product Code: {{ product.sku }}
                  </h5>
                  <p
                    v-if="product.categories"
                    class="product-details-page__content--category"
                  >
                    <span
                      v-for="(category, index) in product.categories"
                      :key="'category'+index"
                    >
                      {{ category.name }} <span v-if="(index+1) !== product.categories.length"> || </span>
                    </span>
                  </p>

                  <p class="product-details-page__content--merchant">
                    <router-link
                      :to="`/vendor/${product.shop.slug}`"
                      class="store-name"
                    >
                      <span>Visit store: </span>
                      <span>{{ product.shop.name }}</span>
                    </router-link>
                  </p>

                  <v-divider />

                  <div class="product-details-page__content--price mt-2">
                    <span>
                      Price: <span style="color: #A62223">{{ variant.price_display | price }}</span>
                    </span>
                    <!--<span v-if="warrantyInformation">-->
                    <!--  ({{-->
                    <!--    `${ warrantyInformation.value } ${ warrantyInformation.value_type } ${ warrantyInformation.value ? 'warranty' : '' }`-->
                    <!--  }})-->
                    <!--</span>-->
                  </div>
                  <div class="product-details-page__content--color">
                    <div
                      class="product-details-page__content--color__content"
                      style="background-color: #006AFF"
                    />

                    <Attributes
                      v-if="product && variant"
                      :product="product"
                      :all-variants="allVariants"
                      :selected-variant="variant"
                      @change="handleAttributeChange"
                    />
                  </div>

                  <div
                    :class="`text-capitalize stock-status text--accent-${availableStock ? '1' : '2'}`"
                  >
                    <span>
                      {{ availableStock ? 'in' : 'out of' }} stock
                    </span>

                    <span v-if="availableStock <= 10 && availableStock > 0">
                      :&nbsp;{{ availableStock }}
                    </span>
                  </div>

                  <div class="product-details-page__content--quantity align-center mb-2">
                    <div
                      v-if="availableStock"
                      class="wrapper"
                    >
                      <button
                        :disabled="quantityCounter < 1 || quantityCounter > availableStock || !availableStock"
                        class="btn btn--minus"
                        type="button"
                        name="button"
                        @click="decrement"
                      >
                        -
                      </button>
                      <input
                        v-model.number="quantityCounter"
                        readonly
                        class="quantity"
                        type="text"
                        name="name"
                      >
                      <button
                        :disabled="quantityCounter === availableStock || !availableStock"
                        class="btn btn--plus"
                        type="button"
                        name="button"
                        @click="increment"
                      >
                        +
                      </button>
                    </div>

                    <v-btn
                      class="mt-5 mr-1"
                      :disabled="!availableStock|| hasReachedStockLimit"
                      color="primary"
                      @click.prevent="handleAddToCart"
                    >
                      <v-icon class="mr-1">
                        mdi-cart
                      </v-icon>
                      <span>Add To Cart</span>
                    </v-btn>
                    <v-btn
                      class="mt-5 ml-md-3"
                      :disabled="!availableStock"
                      color="info"
                      @click.prevent="handleBuyNow"
                    >
                      <v-icon class="mr-1">
                        mdi-cart-arrow-right
                      </v-icon>
                      <span>Buy now</span>
                    </v-btn>
                  </div>

                  <v-divider />

                  <div
                    style="margin-top: 29px !important; margin-left: 10px;"
                    class="product-details-page__content--action mt-2"
                  >
                    <v-row v-if="availableStock">
                      <a
                        :class="!availableStock ? 'disabled' : ''"
                        class="direct-checkout"
                        @click.prevent="handleBuyNow"
                      >
                        direct checkout To buy
                      </a>
                    </v-row>
                    <v-row>
                      <div class="medium mb-2">
                        Have any inquiry?
                      </div>
                    </v-row>
                    <v-row>
                      <a :href="`mailto:${product.shop.email}`">
                        <v-btn
                          color="primary"
                          outlined
                        >
                          <v-icon left>mdi-email</v-icon>
                          Contact Seller
                        </v-btn>
                      </a>
                    </v-row>

                    <v-row v-if="product.tags.length">
                      <div class="tags-wrapper">
                        <v-chip
                          v-for="tag in product.tags"
                          :key="tag.id"
                          small
                          link
                          @click="getProductsByTagName(tag.name)"
                        >
                          {{ tag.name }}
                        </v-chip>
                      </div>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="mt-5 mb-3">
        <div>
          <h3 class="text-h6 mb-2 pl-4 pt-2">
            About this item
          </h3>
          <v-divider />

          <div class="product-details-page__content--quantity d-flex mt-2">
            <v-card-text class="product-description">
              <div
                class="html-content"
                v-html="product.description"
              />

              <div v-if="product.specifications">
                <Specifications :specifications="product.specifications" />
              </div>
            </v-card-text>
          </div>
        </div>
      </v-sheet>
    </v-container>

    <NotFound v-if="!loading && !variant" />
  </div>
</template>

<script>
import './ProductsDetails.scss';
import ProductGallery from '@/components/Product/ProductGallery';
import NotFound from '@/views/Pages/NotFound';
import { isEmpty } from 'lodash';
import LoadingSpinner from '@/components/Utils/LoadingSpinner';
import { addToRecentViewList, storeHelper } from '@/utils/helper';
import store from '@/store';
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import Attributes from '@/components/Product/Details/Attributes';
import { CART_ADD, CART_DATA, cartNameSpace } from '@/store/modules/cart/types';
import routeMap from '@/utils/routeMap';
import Specifications from '@/components/Product/Details/Specifications';
export default defineComponent({
  name: 'ProductsDetails',
  components: { Attributes, LoadingSpinner, ProductGallery, NotFound, Specifications, },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const variant = computed(() => store.getters['product/product'] ?? null);

    const cartState = computed(() => store.getters[`${cartNameSpace}/${CART_DATA}`]);

    const availableStock = computed(() => {
      if (cartState.value.length === 0 || !variant.value)
        return variant.value.stock ?? 0;

      const productInCart = cartState.value.find(item => item.id === variant.value.id);

      return variant.value.stock - (productInCart?.quantity ?? 0);
    });

    // derived state from store
    const ds = reactive({
      loading: computed(() => store.state.product.loading),
    });

    const cart = reactive({
      quantityCounter: 1
    });

    const product = ref(variant.value?.product ?? null);
    const allVariants = ref(product.value?.variations ?? []);

    watch([()=> variant.value], ([newVariant]) => {
      // if product already set don't set again
      if (product.value) return;

      product.value = newVariant.product;
      allVariants.value = newVariant.product.variations;
    });

    const images = computed(() => {
      const images = [];

      if (variant.value?.images) {
        const variantImages = variant.value.images.reduce((imgs, img) => {
          imgs.push({
            id: img.id,
            name: variant.value.full_name,
            variant_id: variant.value.id,
            url: img.url
          });
          return imgs;
        }, []);

        images.push(...variantImages);
      }

      if (product.value?.images) {
        const productImages = product.value.images.reduce((imgs, img) => {
          imgs.push({
            id: img.id,
            name: product.value.name,
            product_id: product.value.id,
            url: img.url
          });
          return imgs;
        }, []);

        images.push(...productImages);
      }

      return images;
    });

    const hasReachedStockLimit = computed(() => {
      if (!variant.value) return false;

      return cart.quantityCounter > availableStock.value;
    });

    // map of actions
    const actions = {
      setProduct: (variation) => store.dispatch('product/setProduct', variation),
      getProduct: ({ productSlug, variationSlug, vendorSLug }) => store.dispatch(
        'product/getProduct',
        { productSlug, variationSlug, vendorSLug }
      ),
      addToCart: (data) => store.dispatch(storeHelper(cartNameSpace, CART_ADD), data)
    };

    // methods

    const handleAttributeChange = ({ lastSelectedAttribute, selectedAttributes }) => {

      // try to find variant with selected attributes criteria
      const matchedVariant = allVariants.value.find((variant) => {

        // flatten variation attributes
        const variantAttributes = variant.attributes.reduce((attrsMap, attr) => {
          attrsMap[attr.name] = attr.value;
          return attrsMap;
        }, {});

        const selectedAttributeKeys = Object.keys(selectedAttributes);

        const matchedAttributesCount = selectedAttributeKeys.filter((attrName) => {

          // if attribute not in variation attribute
          if (!(attrName in variantAttributes)) return false;

          const attrValue = selectedAttributes[attrName];
          const variationAttributeValue = variantAttributes[attrName];

          return (variationAttributeValue === attrValue);
        });

        // if matched attrs are same in count with selected attrs then this variant matches the selection
        return matchedAttributesCount.length === selectedAttributeKeys.length;

      });

      // update matched variant as selected
      if (matchedVariant) {

        actions.setProduct(matchedVariant);
        changeUrl(matchedVariant.slug);
        return;
      }

      // if no variant matched with selection then match with last selection
      const lastSelectionMatchedVariant = allVariants.value.find((variant) => {

        // flatten variation attributes
        return !!variant.attributes.find((attr) => {

          // if the attribute is not selected attribute
          if (attr.name !== lastSelectedAttribute.name) return false;

          return (attr.value === lastSelectedAttribute.value);
        });

      });

      changeUrl(lastSelectionMatchedVariant.slug);
      actions.setProduct(lastSelectionMatchedVariant);

    };

    const changeUrl = (productSlug) => {
      router.push({
        name: routeMap.product.name,
        params: {
          vendor: product.value.shop.slug,
          product: product.value.slug,
          variation: productSlug
        }
      }).catch(() => {});
    };

    const fetchProduct = async () => {
      let productSlug = route.params.product;
      let variationSlug = route.params.variation;
      let vendorSLug = route.params.vendor;

      await actions.getProduct({
        productSlug,
        variationSlug,
        vendorSLug
      });
    };

    const increment = () => {
      if (cart.quantityCounter >= availableStock.value) return;
      cart.quantityCounter = parseInt(cart.quantityCounter, 10) + 1;
    };

    const decrement = () => {
      if (cart.quantityCounter <= 1) return;
      return cart.quantityCounter = parseInt(cart.quantityCounter, 10) - 1;
    };

    const handleAddToCart = () => {

      const data = {
        item: {...variant.value},
        quantity: cart.quantityCounter,
      };


      actions.addToCart(data);

      cart.quantityCounter = 1;

    };

    const handleBuyNow = () => {

      let checkExistsOrNot = store.state.cart.data.find((item) => item.id === variant.value.id);

      if (!checkExistsOrNot) {
        handleAddToCart(variant.value);
      }

      router.push({ name: 'Cart' });
    };

    async function getProductsByTagName (tagName) {
      await router.push({
        name: 'Products',
        query: {
          search: tagName,
        },
      });
    }

    // life cycles events

    onMounted(async () => {

      if (!isEmpty(product.value)) return;
      await fetchProduct();
      const data = {
        ...variant.value,
        stored_at: new Date()
      };

      addToRecentViewList(data);

    });

    return {
      ...toRefs(cart),
      loading: ds.loading,
      variant,
      allVariants,
      product,
      images,
      decrement,
      increment,
      handleBuyNow,
      handleAddToCart,
      handleAttributeChange,
      getProductsByTagName,
      hasReachedStockLimit,
      availableStock,
    };
  },

});
</script>

<style lang="scss">
@import "ProductsDetails";
.direct-checkout {
  text-transform: capitalize;
}
span.v-btn__content span {
  line-height: 2;
  font-size: 14px !important;
  font-weight: 600;
}

.btn {
  &:disabled {
    background-color: rgba(0,0,0,.12);
    color: rgba(0,0,0,.26);
    pointer-events: none;
    border: none;
  }
}

.tags-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 1rem;
  max-width: 450px;
}

@media screen and (min-width: 1366px) {
  .product-description {
    max-width: 50vw;
  }
}

</style>
